import { Link } from "@remix-run/react";
import classNames from "classnames";

export function TextColumn({
  text,
  classes,
  isEnd,
  isStart,
  slug,
}: {
  text: string;
  classes?: string;
  isEnd?: boolean;
  isStart?: boolean;
  slug: string;
}) {
  return (
    <td
      className={classNames("relative", {
        "rounded-e-small": isEnd,
        "rounded-s-small": isStart,
      })}
    >
      <Link to={slug} className="absolute left-0 top-0 size-full" />
      <div className={classNames("flex h-full place-content-center items-center gap-3", classes)}>
        <span className="font-style-body-b3">{text}</span>
      </div>
    </td>
  );
}
